<template>
  <div id="app">
    <ElearningPage :items="imageData" />
  </div>
</template>

<script>
import ElearningPage from "../components/ElearningPage";
export default {
  components: {
    ElearningPage,
  },
  data() {
    return {
      imageData: [], // Array to store data retrieved from the backend
    };
  },
};
</script>
<style scoped></style>

<template>
  <v-container fluid>
    <v-row class="py-16" align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card
          class="text-center mx-auto mt-12 pa-6 pb-8"
          flat=""
          max-width="448"
          rounded="lg"
        >
          <div class="text-h4 font-weight-bold mb-4">Bem-vindo</div>
          <div class="text-body-2 mb-8">
            Faça a gestão de suas páginas de uma forma mais intuitiva
          </div>
          <v-form @submit.prevent="loginUser">
            <!-- Email Input -->

            <v-text-field
              v-model="email"
              placeholder="Email address"
              prepend-inner-icon="mdi-email-outline"
              required
              variant="outlined"
            ></v-text-field>

            <!-- Password Input -->

            <v-text-field
              v-model="password"
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              placeholder="Enter your password"
              prepend-inner-icon="mdi-lock-outline"
              variant="outlined"
              @click:append-inner="visible = !visible"
            ></v-text-field>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              bottom
              color="deep-purple-accent-4"
            ></v-progress-linear>
            <!-- Login Button -->
            <v-scale-transition>
              <div v-if="!loading" class="text-center">
                <v-btn
                  @click="loading = true"
                  type="submit"
                  block
                  color="primary"
                  :disabled="!isFormValid"
                >
                  Log In
                </v-btn>
              </div>
            </v-scale-transition>

            <div class="text-center text-body-2 mt-3">Não tem uma conta?</div>
            <v-card-text class="text-center">
              <router-link
                to="/register"
                class="text-blue text-decoration-none"
                href="#"
                rel="noopener noreferrer"
                target="/register"
              >
                Registe-se agora <v-icon icon="mdi-chevron-right"></v-icon>
              </router-link>
            </v-card-text>
          </v-form>
          <v-alert v-if="emailErrror" type="error" dense>{{
            emailErrror
          }}</v-alert>
          <v-alert v-if="emailError" type="error" dense>{{
            emailError
          }}</v-alert>
          <v-alert v-if="passwordError" type="error" dense>{{
            passwordError
          }}</v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      emailError: null,
      passwordError: null,
      loading: false,
    };
  },

  computed: {
    isFormValid() {
      return this.email && this.password;
    },
  },

  methods: {
    async loginUser() {
      if (this.isFormValid) {
        this.loading = true;
        try {
          const response = await axios.post("https://app.brendkit.com/login", {
            email: this.email,
            password: this.password,
          });

          // Armazenar o token no armazenamento local de forma segura
          localStorage.setItem("token", response.data.token);

          // Redirecionar para a rota desejada
          this.$router.push("/product-update");
        } catch (error) {
          // Mensagem de erro genérica, sem incluir credenciais
          console.error("Erro ao fazer login. Por favor, tente novamente.");

          if (error.response) {
            if (error.response.status === 401) {
              this.passwordError = "Senha ou email incorreto";
              this.emailError = null;
            } else {
              this.passwordError = "Ocorreu um erro ao fazer login";
              this.emailError = null;
            }
          } else {
            this.passwordError = "Ocorreu um erro ao fazer login";
            this.emailError = null;
          }
        } finally {
          this.loading = false;
        }
      } else {
        // If form is not valid, show appropriate error messages
        if (!this.email) {
          this.emailError = "Por favor, insira o seu email.";
        }
        if (!this.password) {
          this.passwordError = "Por favor, insira a sua senha.";
        }
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    const isAuthenticated = localStorage.getItem("token");
    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      !isAuthenticated
    ) {
      next("/login");
    } else {
      next();
    }
  },
};
</script>

<style>
/* Add your custom styles here */
</style>
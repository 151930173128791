<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      v-if="showDrawer"
      :rail="rail"
      permanent
      class="px-2"
      @click="rail = false"
    >
      <v-list-item
        prepend-avatar="https://firebasestorage.googleapis.com/v0/b/brendkit-33911.appspot.com/o/logo%2FRectangle%201.png?alt=media&token=45925cda-6b70-463d-ad6b-bc2ffb3f39ce"
        nav
      >
        Sapataria Itália
      </v-list-item>

      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :class="{ 'blue--text rounded': item.route === activeItem }"
          @click="navigateTo(item.route)"
        >
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>

          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="showDrawer" app>
      <v-app-bar-nav-icon @click.stop="rail = !rail"></v-app-bar-nav-icon>

      <template v-slot:append>
        <v-btn icon="mdi-bell"></v-btn>
        <v-btn @click="logout" icon="mdi-logout"></v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <v-container style="max-width: 100%">
        <!-- Use the router-view to dynamically render the selected component based on the route -->
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      rail: false,
      menuItems: [
        {
          title: "Loja",
          icon: "mdi-page-layout-header-footer",
          route: "/product-update",
        },
        {
          title: "RentAcart",
          icon: "mdi-page-layout-header-footer",
          route: "/rent-a-car",
        },
        {
          title: "Catering",
          icon: "mdi-page-layout-header-footer",
          route: "/catering",
        },
        {
          title: "Elearning",
          icon: "mdi-page-layout-header-footer",
          route: "/elearning",
        },
        {
          title: "StandardWebsites",
          icon: "mdi-page-layout-header-footer",
          route: "/standard-websites",
        },
        {
          title: "Mercearia",
          icon: "mdi-page-layout-header-footer",
          route: "/mercearia-page",
        },

        {
          title: "Eventos",
          icon: "mdi-page-layout-header-footer",
          route: "/eventos",
        },
        {
          title: "Utopia Stoere",
          icon: "mdi-image-multiple",
          route: "/cosmeticos",
        },

        {
          title: "Submissões",
          icon: "mdi-ballot",
          route: "/product-reservation",
        },
        {
          title: "Noticias",
          icon: "mdi-newspaper-variant-outline",
          route: "/page-newsletter",
        },
        {
          title: "Formulários",
          icon: "mdi-image-multiple",
          route: "/page-forms",
        },
      
      ],
    };
  },
  computed: {
    showDrawer() {
      // Hide drawer on login and register pages
      return !["/login", "/", "/register"].includes(this.$route.path);
    },
    showAppBar() {
      // Hide app bar on login and register pages
      return !["/login", "/", "/register"].includes(this.$route.path);
    },
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
      this.activeItem = route;
    },
    logout() {
      // Clear token from local storage
      localStorage.removeItem("token");

      // Redirect to login page
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.v-list-item--nav .v-list-item-title {
  font-size: 1.5rem !important;
}
.blue--text {
  background-color: #1492e6;
  color: white;
}
</style>
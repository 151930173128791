<template>
  <v-sheet class="mx-auto">
    <v-slide-group v-model="model" class="pa-4" selected-class="bg-primary">
      <v-slide-group-item
        v-for="(title, index) in titles"
        :key="index"
        v-slot="{ toggle, selectedClass }"
      >
        <v-card
          :class="['ma-4', selectedClass]"
          height="90"
          variant="outlined"
          width="333.33"
          @click="toggle"
        >
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text>
            <p>{{ descriptions[index] }}</p>
          </v-card-text>
        </v-card>
      </v-slide-group-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet v-if="model != null" height="200">
        <v-card flat>
          <v-card-title class="d-flex align-center pe-2">
            <h4>Tabela de clientes</h4>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              density="compact"
              label="Search"
              single-line
              flat
              hide-details
              variant="solo-filled"
            ></v-text-field>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :items="getTableData"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-img :src="item.image" height="64" cover></v-img>
            </template>

            <template v-slot:[`item.rating`]="{ item }">
              <v-rating
                :model-value="item.rating"
                color="orange-darken-2"
                density="compact"
                size="small"
                readonly
              ></v-rating>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="item.status ? 'green' : 'yellow'"
                :text="item.status ? 'Urgente' : 'Normal'"
                class="text-uppercase"
                label
                size="small"
              ></v-chip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="text-center">
                <v-btn variant="text" icon size="small" @click="viewItem(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  icon
                  size="small"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    reservas: [],
    subscribers: [],
    model: null,
    titles: ["Reservas", "Subscritos"],
    descriptions: [
      "Clique aqui para Visualizar",
      "This form was created to help",
    ],
    search: "",
  }),
  computed: {
    getTableData() {
      switch (this.model) {
        case 0:
          return this.reservas;
        case 1:
          return this.subscribers;
        default:
          return [];
      }
    },
  },
  watch: {
    model: "loadData",
  },
  methods: {
    loadData() {
      switch (this.model) {
        case 0:
          this.fetchReservasData();
          break;
        case 1:
          this.fetchsubscribersData();
          break;
      }
    },
    async fetchReservasData() {
      try {
        const response = await fetch("https://app.brendkit.com/reservas");
        if (!response.ok) {
          throw new Error(
            `Error fetching data from reservas. Status: ${response.status}`
          );
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Invalid content type. Expected JSON.");
        }

        const data = await response.json();
        this.reservas = data.map((item) => ({
          Cliente: item.nomeCompleto,
          Contacto: item.telefone,
          Email: item.email,
          Localizacao: item.location,
          Producto: item.productName,
          Tamanho: item.productSize,
          Preço: item.productPrice,
          Dia: item.createdAt,
          status: true,
          image: item.imageUrl,
          action: "",
        }));
      } catch (error) {
        console.error(error.message);
      }
    },
    async fetchsubscribersData() {
      try {
        const response = await fetch("https://app.brendkit.com/subscribers");
        if (!response.ok) {
          throw new Error(
            `Error fetching data from subscribers. Status: ${response.status}`
          );
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Invalid content type. Expected JSON.");
        }

        const data = await response.json();
        this.subscribers = data.map((item) => ({
          Nome: item.name,
          Contacto: item.contact,
          Email: item.email,
          Localizacao: item.location,
          Mensagem: item.description,
          Dia: item.createdAt,
          action: "",
        }));
      } catch (error) {
        console.error("Error fetching data from subscribers:", error);
      }
    },
    async deleteItem(item) {
  try {
    const confirmDelete = confirm("Do you want to delete this item?");
    if (confirmDelete) {
      let endpoint = '';
      if (this.model === 0) {
        endpoint = `https://app.brendkit.com/reservas/${item._id}`;
      } else if (this.model === 1) {
        endpoint = `https://app.brendkit.com/subscribers/${item._id}`;
      }

      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Item deleted successfully");

        // Update table data after successful deletion
        if (this.model === 0) {
          this.fetchReservasData();
        } else if (this.model === 1) {
          this.fetchsubscribersData();
        }
      } else {
        const data = await response.json();
        console.error("Error deleting item:", data.error);
      }
    }
  } catch (error) {
    console.error("Error deleting item:", error);
  }
},



  },
};
</script>


<template>
  <v-dialog max-width="900">
    <template v-slot:activator="{ props }">
      <v-btn
        prepend-icon="mdi-plus"
        v-bind="props"
        text="Add new product"
      ></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        bottom
        color="deep-purple-accent-4"
      ></v-progress-linear>
      <v-card class="py-4">
        <v-card-text align="center" justify="center"
          >Formulários para postar cursos ou workshops (preview)</v-card-text
        >
        <v-btn
          style="position: absolute; right: 0"
          class="float-right"
          icon
          variant="text"
          @click="isActive.value = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-card-text>
          <div>
            <v-row>
              <v-col>
                <div class="image-container">
                  <div
                    v-for="(image, index) in orderedImages"
                    :key="index"
                    class="image-square"
                  >
                    <img :src="image.url" alt="Uploaded Image" />
                    <v-btn
                      density="compact"
                      size="small"
                      icon="mdi-close"
                      @click="removeImage(index)"
                      class="remove-icon"
                    ></v-btn>
                  </div>
                </div>

                <!-- File input for uploading images -->
                <div class="input-container">
                  <v-card
                    class="py-4 rounded"
                    align="center"
                    justify="center"
                    color="#CFD8DC"
                    elevation="0"
                  >
                    <div align="center" justify="center">
                      <v-btn variant="tex"
                        >Clique para Carregar imagem e videos introdutivo</v-btn
                      >
                    </div>
                  </v-card>
                  <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    multiple
                    class="mb-4 hide-input"
                  />
                </div>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="productName"
                  type="text"
                  placeholder="Nome do curso"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>
                <v-text-field
                  v-model="productPrice"
                  type="number"
                  placeholder="Price "
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>

                <v-text-field
                  v-model="productDuration"
                  type="number"
                  placeholder="Duração (em minutos)"
                  variant="outlined"
                  density="comfortable"
                ></v-text-field>

                <v-text-field
                  v-model="productTargetArea"
                  label="Área Direcionada"
                  outlined
                  density="comfortable"
                ></v-text-field>

                <v-container fluid>
                  <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Text"
                    v-model="productDescription"
                    variant="outlined"
                    density="comfortable"
                  ></v-textarea>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-scale-transition>
            <div v-if="!loading" class="text-center"></div>
          </v-scale-transition>
          <v-btn @click="uploadImages">Salvar producto</v-btn>
        </v-card-actions>
      </v-card>
      <div v-if="showSuccessMessage" class="dialog">
        <v-card class="success-message py-8">
          <div align="center" justify="center">
            <v-btn color="#4CAF50" variant="outlined" icon="mdi-check"></v-btn>
          </div>

          <v-card-text align="center" justify="center">
            Produto adicionado com sucesso!
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      uploadedImages: [],
      loading: false,
      showSuccessMessage: false,
      orderedImages: [],
      selectedFiles: null,
      productName: "",
      productDescription: "",
      productPrice: "",
      productDuration: "",
      productTargetArea: "",
    };
  },
  methods: {
    handleFileChange(event) {
      const files = event.target.files;

      // Ensure that selectedFiles is an array
      this.selectedFiles = this.selectedFiles || [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: files[i],
          });
          this.orderImages(); // Update the ordered images
        };
        reader.readAsDataURL(files[i]);

        // Push each file to selectedFiles array
        this.selectedFiles.push(files[i]);
      }
    },
    orderImages() {
      // You can implement your custom logic for ordering images here
      // For example, you can use drag-and-drop functionality or other UI interactions
      this.orderedImages = [...this.uploadedImages];
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.orderImages(); // Update the ordered images after removal
    },
    // Upload product data and images
    uploadImages() {
      // Verifica se pelo menos uma imagem foi carregada
      if (this.selectedFiles && this.selectedFiles.length > 0) {
        console.log("Sending to database:", this.orderedImages);
        this.loading = true; // Mostra a barra de progresso
        // Reset uploadedImages and orderedImages after sending to the database
        this.uploadedImages = [];
        this.orderedImages = [];
        const formData = new FormData();

        formData.append("productName", this.productName);
        formData.append("productPrice", this.productPrice);
        formData.append("productDuration", this.productDuration);
        formData.append("productTargetArea", this.productTargetArea);
        formData.append("productDescription", this.productDescription);

        // Append all files to the same "images" field
        for (let i = 0; i < this.selectedFiles.length; i++) {
          formData.append("images", this.selectedFiles[i]);
        }

        // Send a single request with all data
        fetch("https://app.brendkit.com/CursoUpload", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then(() => {
            this.loading = false; // Hide progress bar
            this.showSuccessMessage = true; // Show success message
            setTimeout(() => {
              this.showSuccessMessage = false; // Hide success message after a delay
              this.resetForm(); // Reset the form after hiding success message
            }, 3000); // Adjust the delay as needed
          })
          .catch((error) => {
            console.error("Error uploading products:", error);
            this.loading = false; // Hide progress bar in case of an error
          });
      } else {
        // Se nenhuma imagem foi carregada, exibe uma mensagem de erro ou notificação
        console.error("Error: No images uploaded");
        // Aqui você pode exibir uma mensagem para o usuário, por exemplo:
        alert(
          "Por favor, carregue pelo menos uma imagem antes de salvar o produto."
        );
      }
    },
    resetForm() {
      this.uploadedImages = [];
      this.orderedImages = [];
      this.selectedFiles = null;
      this.productName = "";
      this.productDescription = "";
      this.productPrice = "";
      this.productDuration = "";
      this.productTargetArea = "";
    },
  },
};
</script>
<style scoped>
.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-square {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  overflow: hidden;
}

.image-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}
.input-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;

  cursor: pointer;
}
.hide-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>

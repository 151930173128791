// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import ProductUpdate from "@/views/ProductUpdate.vue";
import ProductReservation from "@/views/ProductReservation.vue";
import PageNewsletter from "@/views/PageNewsletter.vue";
import PageForms from "@/views/PageForms.vue";
import LoginPage from "@/views/LoginPage.vue";
import RegisterUser from "@/views/RegisterUser.vue";
import RentAcar from "@/views/RentAcar.vue";
import CateringPage from "@/views/CateringPage.vue";
import ElearningPage from "@/views/ElearningPage.vue";
import StandardWebsites from "@/views/StandardWebsites.vue";
import MerceariaPage from "@/views/MerceariaPage.vue";
import UtopiaUpdate from "@/views/UtopiaUpdate.vue";
import EventosPage from "@/views/EventosPage.vue";



const routes = [
  {
    path: "/product-update",
    component: ProductUpdate,
    meta: { requiresAuth: true },
  },
  {
    path: "/eventos",
    component: EventosPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/product-reservation",
    component: ProductReservation,
    meta: { requiresAuth: true },
  },
  {
    path: "/cosmeticos",
    component: UtopiaUpdate,
    meta: { requiresAuth: true },
  },
  {
    path: "/page-newsletter",
    component: PageNewsletter,
    meta: { requiresAuth: true },
  },
  { path: "/page-forms", component: PageForms, meta: { requiresAuth: true } },
  { path: "/rent-a-car", component: RentAcar }, // Adicione a rota RentACar
  { path: "/catering", component: CateringPage },
  { path: "/elearning", component: ElearningPage },
  { path: "/standard-websites", component: StandardWebsites },
  { path: "/mercearia-page", component: MerceariaPage },
  { path: "/", component: LoginPage },
  { path: "/login", component: LoginPage },
  { path: "/register", component: RegisterUser },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;
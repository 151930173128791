<template>
  <!-- Iniciam os cards -->
  <div></div>
  <v-sheet class="d-flex py-2">
    <v-sheet class="me-auto"><h4>Lista de Eventos</h4></v-sheet>
    <v-sheet> <EventosUpload :items="imageData" /></v-sheet>
  </v-sheet>
  <v-data-iterator
    :items="imageData"
    :items-per-page="itemsPerPage"
    :search="search"
  >
    <template v-slot:header="{ page, pageCount, prevPage, nextPage }">
      <v-row class="d-flex">
        <v-toolbar class="px-2 mt-6">
          <v-col>
            <v-text-field
              v-model="search"
              clearable
              density="comfortable"
              hide-details
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              style="max-width: 300px"
              variant="solo"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-end">
            <div>
              <v-btn variant="text" @click="onClickSeeAll">
                <span class="text-decoration-underline text-none">See all</span>
              </v-btn>
              <v-btn variant="text" @click="toggleShowArchived">
                <span class="text-decoration-underline text-none">
                  {{ showArchived ? 'Hide Archived' : 'Show Archived' }}
                </span>
              </v-btn>
              <div class="d-inline-flex">
                <v-btn
                  :disabled="page === 1"
                  icon="mdi-arrow-left"
                  size="small"
                  variant="tonal"
                  class="me-2"
                  @click="prevPage"
                ></v-btn>
                <v-btn
                  :disabled="page === pageCount"
                  icon="mdi-arrow-right"
                  size="small"
                  variant="tonal"
                  @click="nextPage"
                ></v-btn>
              </div>
            </div>
          </v-col>
        </v-toolbar>
      </v-row>
    </template>
    <template v-slot:default="{ items }">
      <v-row>
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="12"
          sm="6"
          xl="6"
          lg="6"
        >
          <v-sheet border>
            <v-row>
              <v-col>
                <v-carousel show-arrows="hover" height="auto">
                  <v-carousel-item
                    v-for="(image, i) in item.raw.images"
                    :key="i"
                  >
                    <div>
                      {{ console.log("Image Path:", image.path) }}
                      <v-img
                        v-if="image.path"
                        :src="image.path"
                        aspect-ratio="1"
                      ></v-img>
                      <span v-else>No Image Path</span>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col>
                <v-list-item
                  :title="productName"
                  lines="two"
                  density="comfortable"
                >
                  <template v-slot:title>
                    <strong class="text-h6">{{ item.raw.productName }}</strong>
                    <v-badge v-if="item.raw.archived" class="ml-5" color="red" content="Arquivado" floating></v-badge>                  
                  </template>
                </v-list-item>
                <v-table density="compact" class="text-caption">
                  <tbody>
                    <tr align="right">
                      <th>Descrição do evento:</th>
                      <td>{{ item.raw.productDescription }}</td>
                    </tr>
                    <tr align="right">
                      <th>Local do evento:</th>
                      <td>{{ item.raw.productLocal }}</td>
                    </tr>
                    <tr align="right">
                      <th>Data do evento:</th>
                      <td>{{ item.raw.productExpirationDate }}</td>
                    </tr>
                    <tr align="right">
                      <th>Hora do evento:</th>
                      <td>{{ item.raw.eventTime }}</td>
                    </tr>
                    <tr align="right">
                      <th>Tipo do evento:</th>
                      <td>{{ item.raw.productTipo }}</td>
                    </tr>
                    <tr align="right">
                      <th>Nome do convidado:</th>
                      <td>{{ item.raw.productGuest }}</td>
                    </tr>
                    <tr align="right">
                      <th>Endereço:</th>
                      <td>{{ item.raw.productAddress }}</td>
                    </tr>
                    <tr align="right">
                      <th>Mais detalhes do Evento:</th>
                      <td>{{ item.raw.productMoreInfo }}</td>
                    </tr>

                    <tr align="right">
                      <th>Estado do evento:</th>
                      <td>{{ item.raw.productStatus }}</td>
                    </tr>
                    <tr align="right">
                      <th>Entrada:</th>
                      <td>{{ item.raw.productEntry }}</td>
                    </tr>
                  </tbody>
                </v-table>
                <v-list-item>
                  <v-btn @click="openEditDialog(item)" block>Editar</v-btn>
                  <v-btn @click="deleteItem(item)" block>Apagar</v-btn>
                  <v-btn @click="archiveItem(item, 'Event' )" block>
                    {{ item.raw.archived ? 'Desarquivar' : 'Arquivar' }}
                  </v-btn>
                </v-list-item>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer="{ page, pageCount }">
      <v-footer
        color="surface-variant"
        class="justify-space-between text-body-2 mt-4"
      >
        Total de eventos: {{ imageData.length }}
        <div>Página {{ page }} de {{ pageCount }}</div>
      </v-footer>
    </template>
  </v-data-iterator>
  <!-- Terminam os cards -->
  <v-dialog v-model="editDialog" max-width="800">
    <v-card>
      <v-card-title>Editar Evento</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="image-container">
              <div
                v-for="(image, index) in orderedImages"
                :key="index"
                class="image-square"
              >
                <img :src="image.url" alt="Uploaded Image" />
                <v-btn
                  density="compact"
                  size="small"
                  icon="mdi-close"
                  @click="removeImage(index)"
                  class="remove-icon"
                ></v-btn>
              </div>
            </div>
            <div class="input-container">
              <v-card
                class="py-4 rounded"
                align="center"
                justify="center"
                color="#CFD8DC"
                elevation="0"
              >
                <div align="center" justify="center">
                  <v-btn variant="tex">Clique para Carregar imagem</v-btn>
                </div>
              </v-card>
              <input
                type="file"
                ref="fileInput"
                @change="handleFileChange"
                multiple
                class="mb-4 hide-input"
              />
            </div>
          </v-col>
          <v-col>
            <v-text-field
              v-model="productName"
              type="text"
              placeholder="Nome do Evento"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-text-field
              v-model="productDescription"
              type="text"
              placeholder="Descrição do evento"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-text-field
              v-model="productMoreInfo"
              type="text"
              placeholder="Mais detalhes do Evento"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-select
              v-model="productTipo"
              :items="productTipoOptions"
              label="Tipo de Evento"
              variant="outlined"
              density="comfortable"
            ></v-select>
            <v-text-field
              v-model="productLocal"
              type="text"
              placeholder="Local do evento"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-text-field
              v-model="productGuest"
              type="text"
              placeholder="Nome do convidado"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-text-field
              v-model="productAddress"
              type="text"
              placeholder="Endereço"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-text-field
              v-model="productExpirationDate"
              type="date"
              placeholder="Data do evento"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-text-field
              v-model="eventTime"
              type="time"
              placeholder="Hora do evento"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
            <v-select
              v-model="productStatus"
              :items="['normal', 'cancelado', 'adiado']"
              label="Estado  do Evento"
              variant="outlined"
              density="comfortable"
            ></v-select>
            <v-text-field
              v-model="productEntry"
              type="text"
              placeholder="Entrada"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="saveEditedEvent">Salvar</v-btn>
        <v-btn color="error" @click="closeEditDialog">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EventosUpload from "../components/EventosUpload";

export default {
  components: {
    EventosUpload,
  },
  data() {
    return {
      itemsPerPage: 4,
      search: "",
      editDialog: false,
      imageData: [],
      productName: "",
      productDescription: "",
      productMoreInfo: "",
      productTipo: [],
      productTipoOptions: ["Tipo 1", "Tipo 2", "Tipo 3"],
      productLocal: "",
      productGuest: "",
      productAddress: "",
      productExpirationDate: "",
      eventTime: "",
      productStatus: "",
      productEntry: "",
      showArchived: false,
    };
  },
  mounted() {
    // Fetch image data from the backend when the component is mounted
    this.fetchImageData();
  },
  computed: {
    filteredImageData() {
      return this.imageData.filter(item => {
        const matchesSearch = this.search
          ? item.raw.productName.toLowerCase().includes(this.search.toLowerCase())
          : true;
        const matchesArchived = this.showArchived ? true : !item.raw.archived;
        return matchesSearch && matchesArchived;
      });
    },
  },
  methods: {

    async fetchImageData() {
      try {
        const response = await fetch("https://app.brendkit.com/getEventImages");
        const data = await response.json();
        console.log("Received data from the server:", data);

        this.imageData = data.map((item) => ({
          _id: item._id, // Include the product ID
          productName: item.productName,
          productDescription: item.productDescription || "",
          productMoreInfo: item.productMoreInfo || "",
          productLocal: item.productLocal || "",
          productGuest: item.productGuest || "",
          productAddress: item.productAddress || "",
          productTipo: item.productTipo || "",
          productExpirationDate: item.productExpirationDate || "",
          eventTime: item.eventTime || "",
          productStatus: item.productStatus || "",
          productEntry: item.productEntry || "",
          archived: item.archived || false, // Include archived status from backend

          // Flatten the array of images
          images:
            item.images?.map((image) => ({
              ...image,
              path: image.path
                ? image.path.replace(
                    "https://firebasestorage.googleapis.com/v0/b/bucket-name/o/",
                    "https://storage.googleapis.com/bucket-name/"
                  )
                : null,
            })) || [],
        }));
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    },
    openEditDialog(item) {
      // Open the edit dialog and set the edited product data
      this.editDialog = true;
      this.editedEvent = item;

      // Populate the text fields with the targeted product's details
      this.productName = item.productName;
      this.productDescription = item.productDescription;
      this.productMoreInfo = item.productMoreInfo;
      this.productTipo = item.productTipo;
      this.productLocal = item.productLocal;
      this.productGuest = item.productGuest;
      this.productAddress = item.productAddress;
      this.productExpirationDate = item.productExpirationDate;
      this.eventTime = item.eventTime;
      this.productStatus = item.productStatus;
      this.productEntry = item.productEntry;

      // Optionally, you can also update the image data
      this.orderedImages = item.raw.images.map((image) => ({
        ...image,
        url: image.path, // Assuming the path property contains the image URL
      }));
    },

    async saveEditedProduct() {
      try {
        // Prepare the edited product data
        const editedProductData = {
          productName: this.productName,
          productDescription: this.productDescription,
          productMoreInfo: this.productMoreInfo,
          productLocal: this.productLocal,
          productGuest: this.productGuest,
          productAddress: this.productAddress,
          productExpirationDate: this.productExpirationDate,
          eventTime: this.eventTime,
          productStatus: this.productStatus,
          productEntry: this.productEntry,
          

          images: this.orderedImages.map((image) => ({ path: image.url })),
        };
   
        const response = await fetch(
          `https://app.brendkit.com/editEvent/${this.editEvent.raw._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedProductData),
          }
        );

        // Handle the response from the server
        if (response.ok) {
          const data = await response.json();
          console.log("Product updated successfully:", data);

          // Close the edit dialog
          this.closeEditDialog();

          // Refresh the product data displayed in the main view
          this.fetchImageData();
        } else {
          const errorData = await response.json();
          console.error("Error updating product:", errorData.error);
        }
      } catch (error) {
        console.error("Error updating product:", error);
      }
    },

    closeEditDialog() {
      // Close the edit dialog
      this.editDialog = false;
      this.editedPrato = null;
    },

    async editItem(item) {
      try {
        // You can use a router to navigate to an edit page or show a modal for editing
        // For simplicity, let's log the item and open a confirmation dialog
        console.log("Edit item:", item);
        const confirmEdit = confirm("Do you want to edit this item?");
        if (confirmEdit) {
          // Add your edit logic here, for example, navigate to an edit page
          this.$router.push({ name: "edit", params: { id: item._id } });
        }
      } catch (error) {
        console.error("Error editing item:", error);
      }
    },
    async deleteItem(item) {
      try {
        // Show a confirmation dialog before deleting
        const confirmDelete = confirm("Do you want to delete this item?");
        if (confirmDelete) {
          // Log the product ID and name before deletion
          this.logPratoInfo(item);

          // Ensure item._id is valid
          if (!item || !item.raw._id) {
            console.error("Invalid item ID", item);
            return;
          }

          // Log the item ID to verify it
          console.log("Deleting item with ID:", item.raw._id);      

              // Send a request to the server to delete the item
              const response = await fetch(
            `https://app.brendkit.com/getEventImages/${item.raw._id}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();

          if (response.ok) {
            console.log("Item deleted successfully:", data);
            // Refresh the list or update the state after successful deletion
            this.fetchImageData();
          } else {
            console.error("Error deleting item:", data.error);
          }
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    },
      // Send a request to the backend to update the archived status
// Function to archive/unarchive a product
async archiveItem(item, model) {
  // Toggle the archived status of the item locally
  item.archived = !item.archived;

  // Send a request to the backend to update the archived status
  try {
    const response = await fetch(`https://app.brendkit.com/ProductStatus/${model}/${item.raw._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ archived: item.archived }), // Send updated archived status
    });

    if (response.ok) {
      console.log('Product status updated successfully');
    } else {
      console.error('Failed to update product status');
    }
  } catch (error) {
    console.error('Error updating product status:', error);
  }
},

    logPratoInfo(item) {
      if (item && item.raw) {
        console.log("Product ID:", item.raw._id);
        console.log("Product Name:", item.raw.productName);
      } else {
        console.error("Invalid item object:", item);
      }
    },

    onClickSeeAll() {
      this.itemsPerPage = this.itemsPerPage === 4 ? this.imageData.length : 4;
    },
    toggleShowArchived() {
      // Toggle the showArchived flag
      this.showArchived = !this.showArchived;
    },
    handleFileChange(event) {
      const files = event.target.files;

      // Ensure that selectedFiles is an array
      this.selectedFiles = this.selectedFiles || [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: files[i],
          });
          this.orderImages(); // Update the ordered images
        };
        reader.readAsDataURL(files[i]);

        // Push each file to selectedFiles array
        this.selectedFiles.push(files[i]);
      }
    },
    orderImages() {
      // You can implement your custom logic for ordering images here
      // For example, you can use drag-and-drop functionality or other UI interactions
      this.orderedImages = [...this.uploadedImages];
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
      this.orderImages(); // Update the ordered images after removal
    },
    uploadImages() {
      console.log("Sending to database:", this.orderedImages);
      this.loading = true; // Show progress bar
      // Reset uploadedImages and orderedImages after sending to the database
      this.uploadedImages = [];
      this.orderedImages = [];
      const formData = new FormData();

      formData.append("productName", this.productName);
      formData.append("productLocal", this.productLocal);
      formData.append("productGuest", this.productGuest);
      formData.append("productAddress", this.productAddress);
      formData.append("productDescription", this.productDescription);
      formData.append(" productInfo", this.productInfo);
      formData.append("productExpirationDate", this.productExpirationDate);
      formData.append("eventTime", this.eventTime);
      formData.append("productStatus", this.productStatus);
      formData.append("productEntry", this.productEntry);

      // Append all files to the same "images" field
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("images", this.selectedFiles[i]);
      }

      // Send a single request with all data
      fetch("https://app.brendkit.com/EventosUpload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(() => {
          this.loading = false; // Hide progress bar
          this.showSuccessMessage = true; // Show success message
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide success message after a delay
            this.resetForm(); // Reset the form after hiding success message
          }, 3000); // Adjust the delay as needed
        })
        .catch((error) => {
          console.error("Error uploading products:", error);
          this.loading = false; // Hide progress bar in case of an error
        });
    },
  },
};
</script>

<style scoped>
.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-square {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  overflow: hidden;
}

.image-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}
.input-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;

  cursor: pointer;
}
.hide-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>